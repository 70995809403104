import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class PharmacyFileService {
  apiUrl: string = environment.apiUrl;
  constructor(private http: HttpClient) {}
 
  get510PdfDetails(id:any) {
    const currEndPoint = this.apiUrl + `pharmacy-file/get510pdfdetails/${id}`;
    return this.http.get(currEndPoint);
  }

  get58TableList(
    siteid: number,
    sponsorid: number,
    projectid: number,
    resourceid: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `pharmacy-file/get58list/${siteid}/${sponsorid}/${projectid}/${resourceid}`;
    return this.http.get(currEndPoint);
  }

  create58Record(data: any) {
    const currEndPoint = this.apiUrl + `pharmacy-file/createdruginventorylog`;
    return this.http.post(currEndPoint, data);
  }

  update58Record(id: number, data: any) {
    const currEndPoint =
      this.apiUrl + `pharmacy-file/updatedruginventorylog/${id}`;
    return this.http.put(currEndPoint, data);
  }

  // 5.8
  getById(id: number) {
    const currEndPoint =
      this.apiUrl + `pharmacy-file/get58druginventorylog/${id}`;
    return this.http.get(currEndPoint);
  }

  getReceivedByList(projectid: number, siteid: number, sponsorid: number) {
    const currEndPoint =
      this.apiUrl +
      `pharmacy-file/getreceiverlist/${projectid}/${siteid}/${sponsorid}`;
    return this.http.get(currEndPoint);
  }

  getShipmentConditon() {
    const currEndPoint = this.apiUrl + `pharmacy-file/getshipmentlist`;
    return this.http.get(currEndPoint);
  }

  getDrugIpnumber(projectid: number) {
    const currEndPoint = this.apiUrl + `pharmacy-file/getdruglist/${projectid}`;
    return this.http.get(currEndPoint);
  }

  delete58Record(id: number, data: any) {
    const currEndPoint = this.apiUrl + `pharmacy-file/delete58log/${id}`;
    return this.http.put(currEndPoint, data);
  }

  update58CraVerification(data) {
    const currEndPoint = this.apiUrl + `pharmacy-file/update58craverification`;
    return this.http.post(currEndPoint, data);
  }
  
  get58GlobalHistory(
    projectid:number,
    categoryid:number,
    subcategoryid:number,
    contenttypeid:number,
    subcontenttypeid:number,
  ){
    const currEndPoint = this.apiUrl +`pharmacy-file/getisf58globalaudithistory/${projectid}/${categoryid}/${subcategoryid}/${contenttypeid}/${subcontenttypeid}`;
    return this.http.get(currEndPoint);
  }

  // 5.9
  get59ById(id: any) {
    const currEndPoint =
      this.apiUrl + `pharmacy-file/get59drugdispensorylog/${id}`;
    return this.http.get(currEndPoint);
  }
  
  get59GlobalHistory(
    projectid:number,
    categoryid:number,
    subcategoryid:number,
    contenttypeid:number,
    subcontenttypeid:number,
  ){
    const currEndPoint = this.apiUrl +`pharmacy-file/getisf59globalaudithistory/${projectid}/${categoryid}/${subcategoryid}/${contenttypeid}/${subcontenttypeid}`;
    return this.http.get(currEndPoint);
  }

  get59List(
    siteid: number,
    sponsorid: number,
    projectid: number,
    resourceid: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `pharmacy-file/get59list/${siteid}/${sponsorid}/${projectid}/${resourceid}`;
    return this.http.get(currEndPoint);
  }

  create59Record(data: any) {
    const currEndPoint = this.apiUrl + `pharmacy-file/createdrugdispensorylog`;
    return this.http.post(currEndPoint, data);
  }

  update59Record(id: number, data: any) {
    const currEndPoint =
      this.apiUrl + `pharmacy-file/updatedrugdispensorylog/${id}`;
    return this.http.put(currEndPoint, data);
  }

  delete59Record(id: number, data: any) {
    const currEndPoint = this.apiUrl + `pharmacy-file/delete59log/${id}`;
    return this.http.put(currEndPoint, data);
  }

  update59CraVerification(data) {
    const currEndPoint = this.apiUrl + `pharmacy-file/update59craverification`;
    return this.http.post(currEndPoint, data);
  }

  getDrugQuantityList(
    siteid: number,
    sponsorid: number,
    projectid: number,
    resourceid: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `pharmacy-file/get59drugquantitylist/${siteid}/${sponsorid}/${projectid}/${resourceid}`;
    return this.http.get(currEndPoint);
  }

  getDispensedByList(projectid: number, siteid: number, sponsorid: number) {
    const currEndPoint =
      this.apiUrl +
      `pharmacy-file/getreceiverlist/${projectid}/${siteid}/${sponsorid}`;
    return this.http.get(currEndPoint);
  }
  
  get59DrugQuantityList(
    siteid: number,
    sponsorid: number,
    projectid: number,
    resourceid: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `pharmacy-file/get59drugquantitylist/${siteid}/${sponsorid}/${projectid}/${resourceid}`;
    return this.http.get(currEndPoint);
  }

 

  // 5.10
  get510TableList(
    sponsorid: number,
    projectid: number,
    siteid: number,
    resourceid: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `pharmacy-file/get510list/${sponsorid}/${projectid}/${siteid}/${resourceid}`;
    return this.http.get(currEndPoint);
  } 

  get510GlobalHistory(
    projectid:number,
    categoryid:number,
    subcategoryid:number,
    contenttypeid:number,
    subcontenttypeid:number,
  ){
    const currEndPoint = this.apiUrl +`pharmacy-file/getisf510globalaudithistory/${projectid}/${categoryid}/${subcategoryid}/${contenttypeid}/${subcontenttypeid}`;
    return this.http.get(currEndPoint);
  }

  get510DocumentTitleList(
    projectid:number,
    siteid:number,
    categoryid:number,
    subcategoryid:number,
    contenttypeid:number,
    subcontenttypeid:number,
  ){
    const currEndPoint = this.apiUrl +  `pharmacy-file/getisfadddocumenttitle510/${projectid}/${siteid}/${categoryid}/${subcategoryid}/${contenttypeid}/${subcontenttypeid}`;
    return this.http.get(currEndPoint);
  }

  getReasonOfDesctruction() {
    const currEndPoint =
      this.apiUrl + `pharmacy-file/get510destructionreasonlist`;
    return this.http.get(currEndPoint);
  }

  getMethodOfDesctruction() {
    const currEndPoint = this.apiUrl + `pharmacy-file/get510destructionlist`;
    return this.http.get(currEndPoint);
  }

  getDestructionBylist(projectid: number, siteid: number, sponsorid: number) {
    const currEndPoint =
      this.apiUrl +
      `pharmacy-file/getreceiverlist/${projectid}/${siteid}/${sponsorid}`;
    return this.http.get(currEndPoint);
  }

  create510List(data: any) {
    const currEndPoint = this.apiUrl + `pharmacy-file/create510destructionlog`;
    return this.http.post(currEndPoint, data);
  }

  update510List(id: any, data: any) {
    const currEndPoint =
      this.apiUrl + `pharmacy-file/update510destructionlog/${id}`;
    return this.http.put(currEndPoint, data);
  }

  delete510Record(id: any, data: any) {
    const currEndPoint = this.apiUrl + `pharmacy-file/delete510log/${id}`;
    return this.http.put(currEndPoint, data);
  }

  get510RecordById(id: any) {
    const currEndPoint =
      this.apiUrl + `pharmacy-file/get510drugdestructionlog/${id}`;
    return this.http.get(currEndPoint);
  }

  update510CraVerification(data) {
    const currEndPoint = this.apiUrl + `pharmacy-file/update510craverification`;
    return this.http.post(currEndPoint, data);
  }
  
  update510DestructedBy(data){
    const currEndPoint = this.apiUrl + `pharmacy-file/destructedbyverification510`;
    return this.http.post(currEndPoint, data);
  }

  get615List(projecid:number){
    const currEndPoint = this.apiUrl + `pharmacy-file/getisftotmf615list/${projecid}`;
    return this.http.get(currEndPoint);
  }

  get6110List(sponsorid:number,projecid:number){
    const currEndPoint = this.apiUrl + `pharmacy-file/getisftotmf6110list/${sponsorid}/${projecid}`;
    return this.http.get(currEndPoint);
  }

 getTMF615InventoryList(projecid:number){
  const currEndPoint = this.apiUrl + `pharmacy-file/getisftotmf615inventorylist/${projecid}`;
  return this.http.get(currEndPoint);
 }

 getTMF615Dispensorylist(projecid:number){
  const currEndPoint = this.apiUrl + `pharmacy-file/getisftotmf615dispensorylist/${projecid}`;
  return this.http.get(currEndPoint);
 }

}
